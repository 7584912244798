import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트샵, 8일(금)부터 아이폰13 자급제폰 구매고객 위한 유심요금제 프로모션",
  "| 구입처 상관없이 아이폰13 자급제 구매 인증만 하면 월1만원대 요금제부터 5G/LTE 5종 신청 가능",
  "| 중고폰 최대 10만원 특별 보상, 8만원 상당 맥세이프 및 벨킨 정품 고속 충전기 등 풀케어 특별혜택",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/25/prYgKNkf.png" alt="Figure1" />
      </div>
      <div className="desc">
        △핀다이렉트샵이 아이폰13 자급제 고객을 위해 오는 8일(금)부터 31일(일)까지 <br />
        ‘아이폰13 자급제폰 풀케어’ 프로모션을 진행한다.
      </div>
    </div>
    <p>
      <br />
      <br />
      아이폰13 자급제 품귀현상이 이어지는 가운데 한국 사전예약 신청이 오늘(7일) 마무리된다. 자급제와 알뜰폰 요금제 꿀조합에 이어
      이제 풀케어 혜택까지 챙길 수 있는 기회가 왔다.
      <br />
      <br />
      스테이지파이브(대표 서상원)의 통신 판매 플랫폼 ‘핀다이렉트샵’은 아이폰13 자급제 구매 고객을 위한 10월 한정 ‘아이폰13
      자급제폰 풀케어’ 프로모션을 마련했다.
      <br />
      <br />
      핀다이렉트샵이 오는 8일(금)부터 31일(일)까지 진행하는 이번 풀케어 프로모션은 고객의 구매ㆍ 사용 ㆍ 수리 케이스를 고려한 세
      가지 케어 혜택과 함께 합리적인 유심 요금제(알뜰폰) 구성으로 마련했다.
      <br />
      <br />
      아이폰13 자급제 전용 풀케어 혜택 요금제는 총 5종으로 5GㆍLTE 유심 무제한 요금제이다. △’완전자유 뭐 이런걸 다
      11GB+(기프티쇼)’ (LTEㆍ월11GB+일2GB+3Mbps+매월 기프티쇼 쿠폰 제공) △’데이터안심 15GB+ (기프티쇼)’ (LTEㆍ월 15GB+ 3Mbps+매월
      기프티쇼 쿠폰 제공) △’완전자유 11GB+’ (LTEㆍ월 11GB+일2GB+3Mbps) △’5G 슬림 10GB+’(5Gㆍ월10GB+1Mbps) △’완전자유 실속 1GB+’
      (LTEㆍ월 1GB+평생6GB추가+1Mbps) 등이다. 월 1만원대부터 3만원대까지 요금 구성도 다양하다.
      <br />
      <br />
      세 가지 풀케어 혜택은 △중고폰 시세 대비 최대 10만원 추가 특별 보상 △자석형 무선 충전기 ‘맥 세이프(MagSafe)’ 및 벨킨 정품
      휴대용 고속 충전기 (총 8만원 상당) △애플케어플러스(AppleCare+) 가입 증빙 시 5만원 편의점 상품권 혜택이 제공된다.
      <br />
      <br />
      이번 프로모션은 아이폰13 시리즈 전 모델의 자급제 구매를 증빙한 고객이면 구입처나 컬러, 용량에 상관없이 모두 신청 가능하다.
      자세한 내용은 핀다이렉트샵(www.pindirectshop.com)에서 확인 가능하다.
      <br />
      <br />
      | 비대면 가입은 물론, 통신사 기기결합 및 자급제까지 한번에…핀다이렉트샵
      <br />
      <br />
      <div className="descImage">
        <div className="img">
          <img src="/images/press/25/HySLxPVJ.jpg" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure2" />
        </div>
      </div>
      <br />
      통신업계에서 통신사 기기결합으로 아이폰13 사전예약 판매를 진행하며 자급제를 위한 유심 요금제를 함께 취급하는 플랫폼은 찾기
      어렵다. 그러나 스테이지파이브 통신 판매 플랫폼 핀다이렉트샵은 스마트폰 디바이스와 다양한 요금제 등 통신 관련 상품을 한 데
      모아 소비자 선택권을 확대하는 데 초점을 두고 있다.
      <br />
      <br />
      핀다이렉트샵에서는 최신상 스마트폰과 합리적인 요금이 매칭된 기기결합 요금제, 자급제폰과 찰떡궁합인 알뜰폰(MVNO) 유심 요금제
      등 다양한 종류의 통신 요금제도 간편하게 비교할 수 있다. 신한 플러스, 멜론, SPC 요금제 등 이종산업간 결합한 핀다이렉트샵 만의
      제휴요금제도 준비돼 있다. 뿐만 아니라 최신 5G 스마트폰은 물론, 2단계 품질 검수를 거치는 ‘핀다이렉트케어’ 중고폰까지 한 곳에
      모여 있어 고객 선택권도 한층 강화됐다.
      <br />
      <br />
      뿐만 아니라 핀다이렉트샵은 세련되고 편리한 UX 설계로 MZ세대에 좋은 반응을 얻고 있는 비대면 통신 가입 플랫폼이다. PC는 물론
      카카오톡 채널을 비롯하여 웹과 모바일을 통한 접근이 가능하며, 카카오페이를 통한 본인인증 등 가입 절차, 배송 및 상담 등 구매
      이후 절차 역시 카카오톡으로 간편하게 이용할 수 있다. 이곳저곳 흩어진 포인트를 한 번에 모아 선 할인 받을 수 있는 것도
      강점이다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 10월 7일 즉시 배포 가능"
      pageInfo="*총 2매 / 첨부사진 2매"
      title="“아이폰13 자급제족 모여라!”<br/> 10월 한정, 핀다이렉트샵 아이폰13 풀케어 특별혜택 오픈"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
